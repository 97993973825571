import React from 'react'
import styled from 'styled-components'

import Layout from 'src/components/Layout'

const Container = styled.div`
  width: 100%;
  height: 90vh;
`

const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  place-items: center;

  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <Container>
      <MaxWidthContainer>
        <h1>Not Found:</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </MaxWidthContainer>
    </Container>
  </Layout>
)

export default NotFoundPage
